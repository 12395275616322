import React from "react"
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Slider,
} from "pure-react-carousel"
import { Flex } from "reflexbox"
import { css } from "@emotion/core"

import "pure-react-carousel/dist/react-carousel.es.css"

import LeftArrow from "~/images/left-arrow.svg"

import { mq } from "~/components/common/theme"

const dotSize = mq({
  height: ["16px", "16px", "24px"],
  width: ["16px", "16px", "24px"],
  minHeight: ["16px", "16px", "24px"],
  minWidth: ["16px", "16px", "24px"],
  marginRight: ["9px", "13px", "15px"],
})

const arrowSize = mq({
  height: ["20px", "45px", "75px"],
  width: ["20px", "45px", "75px"],
  minHeight: ["20px", "45px", "75px"],
  minWidth: ["20px", "45px", "75px"],
})

const arrowLocation = mq({
  top: ["0px"],
  bottom: ["0px"],
})

const Carousel = ({ children, ...props }) => {
  return (
    <CarouselProvider
      totalSlides={React.Children.count(children)}
      css={css`
        position: relative;
        button {
          background-color: unset;
          border-style: none;
        }
        button:active,
        button:active:disabled {
          border-style: none;
        }
        button:focus {
          outline: none;
        }
      `}
      {...props}
    >
      <Slider>{children}</Slider>
      <ButtonBack
        css={[
          css`
            position: absolute;
            left: 0px;
            border-color: unset;
            color: transparent;
            background-color: transparent;
          `,
          // mq({ display: ["none", "none", "inline-block"] }),
          arrowLocation,
        ]}
      >
        <LeftArrow
          css={[
            css`
              fill: black;
            `,
            arrowSize,
          ]}
        ></LeftArrow>
      </ButtonBack>
      <ButtonNext
        css={[
          css`
            position: absolute;
            right: 0px;
            border-color: unset;
            color: transparent;
            background-color: transparent;
          `,
          // mq({ display: ["none", "none", "inline-block"] }),
          arrowLocation,
        ]}
      >
        <LeftArrow
          css={[
            css`
              fill: black;
              transform: rotate(180deg);
            `,
            arrowSize,
          ]}
        ></LeftArrow>
      </ButtonNext>
      <Flex
        justifyContent={"center"}
        alignItems="center"
        mt={2}
        mb={4}
        width={"100%"}
      >
        <DotGroup
          css={css`
            display: block;
            margin-top: 15px;
            button {
              ${dotSize};
              border-radius: 50%;
              padding: 0;
            }
          `}
        ></DotGroup>
      </Flex>
    </CarouselProvider>
  )
}

export default Carousel
