import React, { useState } from "react"

import Layout from "~/components/common/Layout"
import {
  getBackLink,
  getHeaderTitles,
  getNextLink,
  getNextTitle,
} from "~/services/pages"
import withProgress from "~/services/withProgress"
import { css } from "@emotion/core"
import HeroVideo from "~/components/page/HeroVideo"
import { graphql, useStaticQuery } from "gatsby"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"
import { Box, Flex } from "reflexbox"
import { Text, Ul } from "~/components/common/Typography"
import Carousel from "~/components/page/Carousel"
import { Slide } from "pure-react-carousel"
import Img from "gatsby-image"
import { rgba } from "polished"
import useDimensions from "react-use-dimensions"
import Copyright from "~/components/common/Copyright"
import { Accordion } from "~/components/common/Accordion"
import Source from "~/components/common/Source"
import ErrorBoundary from "~/components/common/ErrorBoundary"

const Page = ({ myPage = 4, mySubpage = 2 }) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(name: { eq: "5.3HERO" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic1: file(name: { eq: "5.3a-ADIDAS-basketball" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic2: file(name: { eq: "5.3b-ADIDAS-leadership" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic3: file(name: { eq: "5.3c-ADIDAS-conversation" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const [isOpen, setIsOpen] = useState({ 0: false, 1: false })
  const [ref, { x, height, width }] = useDimensions()

  const { hero, graphic1, graphic2, graphic3 } = data

  const sliderData = [
    {
      image: graphic1,
      header: "Tell it",
      text: "Name the action or behavior you are praising.",
      example: "Example: “Thanks for helping up a teammate!”",
    },
    {
      image: graphic2,
      header: "Label it",
      text:
        "Tell the player what this action is called. Give it a label that she can now identify with.",
      example: "Example: “That’s called sportsmanship.”",
    },
    {
      image: graphic3,
      header: "Celebrate it",
      text:
        "This not only acknowledges the behavior in a positive way, but also shows others what behaviors you are looking for.",
      example: "Example: “Let’s tell the rest of the team.”",
      ref: ref,
    },
  ]

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="In order to best teach skills, you have to consider that some girls may be dealing with a significant amount of stress that impacts how their brains and bodies function."
        image={hero}
        bg={"white"}
      />

      <Infobox uppercase>
        You’ve identified techniques that can be particularly helpful in
        supporting girls who may be living with varying levels of stress. These
        tactics aren’t only for girls facing stress, they can also be beneficial
        for all kids.
      </Infobox>

      <InfoboxDetailed
        as={"section"}
        headerText={"Asking the right questions"}
        contentMaxWidth={"mediumContent"}
      >
        <Text>
          Asking your players questions gives them the opportunity to practice
          critical thinking that is valuable to their development and success on
          and off the field. But they must be the right kind of questions.
        </Text>
        <Box my={6}>
          <Accordion
            boldHeader
            heading={<span>Growth questions</span>}
            onToggle={() =>
              setIsOpen(s => {
                return Object.assign({}, s, { [0]: !s[0] })
              })
            }
            isOpen={isOpen[0]}
          >
            <Box textAlign={"left"}>
              <Text>
                These are open-ended questions that force your players to stop
                and think. They help players reflect critically about ways they
                can improve and grow. These questions require players to be calm
                and to think rationally. The more you ask questions like these,
                the more familiar the players will become with the process of
                reflecting and thinking through difficult situations. They’ll
                also be more confident in the future when faced with similar
                situations in or out of sports.<Source>38</Source>
              </Text>
              <Text>Some examples:</Text>
              <Ul>
                <li>How do you feel?</li>
                <li>What do you see out there on the field?</li>
                <li>What were you thinking when you made that choice?</li>
                <li>What are your options? What other options do you have?</li>
                <li>What would you do next time?</li>
                <li>How did you do that?</li>
              </Ul>
              <Text>
                Tip: It’s important to make sure that open-ended questions are
                not asked in a way that feels judgmental or leading.
              </Text>
            </Box>
          </Accordion>
        </Box>
        <Box my={5}>
          <Accordion
            boldHeader
            heading={"Calming Questions"}
            onToggle={() =>
              setIsOpen(s => {
                return Object.assign({}, s, { [1]: !s[1] })
              })
            }
            isOpen={isOpen[1]}
          >
            <Box textAlign={"left"}>
              <Text>
                These are questions that take the focus of a player away from
                potentially triggering or stressful circumstances. By shifting
                focus, you as a coach can help her calm down, feel safe, and
                resume thinking critically. The goal of these questions is to
                momentarily remove the player from a stressful situation and
                place her mind and attention on a more calming and neutral
                topic.
              </Text>
              <Text>
                You can use this opportunity to de-escalate a situation and
                begin the process of working through a challenging moment with
                the player. A player who is triggered is not able to think
                critically, learn, or be her best self. As a coach, you can help
                your players manage these situations by working through these
                feelings so they can reflect back and grow from it later in
                life.
              </Text>
              <Text>Some examples:</Text>
              <Ul>
                <li>Did you know giraffes can’t jump?</li>
                <li>What’s your favorite movie?</li>
                <li>If you could have any superpower, what would it be?</li>
              </Ul>
            </Box>
          </Accordion>
        </Box>
      </InfoboxDetailed>

      <ErrorBoundary>
        <InfoboxDetailed
          as="section"
          flexDirection="column"
          justifyContent="start"
          pt="6"
          px="6"
          pb="6"
          bg="seafoam"
          headerText={"TLC praise"}
          headerMaxWidth={"desktop"}
          headerBackground={"transparent"}
        >
          <Box maxWidth="tablet" mx={"auto"}>
            <Text>
              Giving positive praise is good, but not all praise has the same
              effect. The TLC strategy will explore how you can use praise to be
              as impactful as possible.
            </Text>
            <Text>
              “Hey, Kenzie, when you helped your teammate up after she fell,
              that showed great sportsmanship! Great job, let’s go tell the rest
              of the team!”
            </Text>
          </Box>
          <Box
            maxWidth="desktop"
            mx="auto"
            bg={rgba("white", 0.8)}
            mt="5"
            px={[5, 5, 6]}
            py={[6]}
          >
            <Box mx="auto" bg="white" px={[5, 5]} py={[4, 5]}>
              <Carousel>
                {sliderData.map((slide, index) => (
                  <Slide
                    key={index}
                    index={index}
                    style={{ height: `${height + 10}px` }}
                  >
                    <Flex
                      flexDirection={["column"]}
                      alignItems={"center"}
                      justifyContent={"center"}
                      width={["90%", "90%", "70%"]}
                      mt={[4, 5]}
                      mx={"auto"}
                      height={slide.ref ? "unset" : "100%"}
                      ref={slide.ref}
                    >
                      <Box width={["70%"]}>
                        <Img
                          fluid={slide.image.childImageSharp.fluid}
                          loading={"eager"}
                          css={css`
                            height: 100%;
                            width: 100%;
                          `}
                        />
                      </Box>
                      <Box width={["100%"]}>
                        <Text
                          uppercase
                          textAlign={"center"}
                          fontFamily={"AdineuePROBlackWeb"}
                          fontSize={["18px", "24px"]}
                          lineHeight={["27px", "29px"]}
                          my={0}
                        >
                          {slide.header}
                        </Text>
                        <Text
                          mt={0}
                          textAlign={"center"}
                          fontSize={["16px", "16px", "18px"]}
                          lineHeight={["24px", "24px", "36px"]}
                        >
                          {slide.text}
                          <br /> {slide.example}
                        </Text>
                      </Box>
                    </Flex>
                  </Slide>
                ))}
              </Carousel>
            </Box>
          </Box>
        </InfoboxDetailed>
      </ErrorBoundary>

      <InfoboxDetailed
        headerMaxWidth={"desktop"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
      >
        <Flex flexDirection={"column"}>
          <Box maxWidth={"mediumContent"} mx={"auto"} width={"100%"}>
            <Text>
              TLC praise is powerful because it is specific. By naming the
              behavior, you are showing that you are paying attention. It is
              something the player cannot deny or brush off. Using a specific
              example makes the praise more believable.<Source>39</Source>
            </Text>
            <Text>
              It also gives positive words for the players to identify with.
              Labeling the behaviors gives players new attributes to identify
              with. Girls often hear negative associations, so we want to give
              them more positive words to help them define how they view
              themselves.
            </Text>
            <Text>
              Your use of TLC praise will help girls learn to separate who they
              are and how they are accepted from their performance. Give them
              praise for specific behaviors, and personalize your interactions
              to help your athletes believe that you want them to succeed.
            </Text>

            <Text
              uppercase
              textAlign={"center"}
              fontFamily={"AdineuePROBlackWeb"}
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "29px"]}
            >
              {" "}
              TLC praise helps strengthen your players in ways that will benefit
              them in all walks of life.
            </Text>
            <Text
              uppercase
              textAlign={"center"}
              fontFamily={"PermanentMarkerRegular"}
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "29px"]}
              mt={6}
            >
              "I have found that a lot of women do not have the confidence to
              feel they are as good as they actually are, we use our videos as
              highlight reels to build their confidence.”
            </Text>
            <Text
              uppercase
              textAlign={"center"}
              fontFamily={"AdineuePROBoldWeb"}
              fontSize={["16px", "18px"]}
              lineHeight={["18px", "21px"]}
              maxWidth={"450px"}
              mx={"auto"}
              mt={0}
            >
              —Anson Dorrance, coach of UNC’s women’s soccer team.
              <Source>40</Source>
            </Text>
          </Box>
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerMaxWidth={"950px"}
        maxWidth={"100%"}
        contentMaxWidth={"tablet"}
        headerText={"Communication with girls matters"}
        pt={0}
      >
        <Flex flexDirection={"column"}>
          <Box mx={"auto"}>
            <Text>
              Girls respond to criticism and praise differently, and the
              language you use to communicate either is important. Girls will
              listen less to what you say than to how you say it. They pay
              attention to your tone and your body language. If either of those
              is negative, that’s the message, regardless of what comes out of
              your mouth.<Source>41</Source>
            </Text>
            <Text>
              "Coaches who berate and belittle girls turn sports into such a
              hurtful, harmful experience that dropping out becomes for many a
              way to avoid further damage to their self-esteem.”
              <Source>42</Source>
            </Text>
          </Box>
        </Flex>
      </InfoboxDetailed>

      <Copyright />
    </Layout>
  )
}

export default withProgress(Page, 4, 2)
